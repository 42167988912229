<template>
  <div>
    <!-- <p>{{ caseData.data }}</p> -->
    <!-- Basic Details -->
    <basic-details :data="caseData"></basic-details>
    <!-- Case Status -->
    <div v-if="Object.keys(caseData.data).includes('case_details')">
      <case-status
        :matter="caseData.data"
        :detail="caseData.data.case_details"
      ></case-status>
    </div>
    <!-- Category -->
    <div v-if="Object.keys(caseData.data).includes('category')">
      <category-details :matter="caseData.data"></category-details>
    </div>
    <!-- Linked Matters -->
    <div v-if="Object.keys(caseData.data).includes('linked_matters')">
      <linked-matters
        v-if="caseData.data.linked_matters"
        :matter="caseData.data"
      ></linked-matters>
    </div>
    <!-- Peitioner & Respondent -->
    <div
      v-if="
        Object.keys(caseData.data).includes('petitioner_advocates') ||
        Object.keys(caseData.data).includes('respondent_advocates')
      "
    >
      <petitioner-respondents :matter="caseData.data"></petitioner-respondents>
    </div>

    <!-- Copy Petition Details -->
    <div v-if="Object.keys(caseData.data).includes('copy_petition_details')">
      <basic-table
        :data="caseData.data.copy_petition_details"
        :title="'Copy Petition Details'"
      ></basic-table>
    </div>

    <!-- Lower Court Details -->
    <div v-if="Object.keys(caseData.data).includes('lower_court_detail')">
      <basic-table
        :data="caseData.data.lower_court_detail"
        :title="'Lower Court Details'"
      ></basic-table>
    </div>

    <!-- FIR Details -->
    <div v-if="Object.keys(caseData.data).includes('fir_details')">
      <basic-table
        :data="caseData.data.fir_details"
        :title="'FIR Details'"
      ></basic-table>
    </div>

    <!-- Impung Order Details -->
    <div v-if="Object.keys(caseData.data).includes('impung_order_detail')">
      <basic-table
        :data="caseData.data.impung_order_detail"
        :title="'Impung Order Details'"
      ></basic-table>
    </div>

    <!-- Complaint Details -->
    <div v-if="Object.keys(caseData.data).includes('complaint_details')">
      <basic-table
        :data="caseData.data.complaint_details"
        :title="'Complaint Details'"
      ></basic-table>
    </div>

    <!-- Lac Details -->
    <div v-if="Object.keys(caseData.data).includes('lac_detail')">
      <basic-table
        :data="caseData.data.lac_detail"
        :title="'Lac Details'"
      ></basic-table>
    </div>

    <!-- Listening History -->
    <div v-if="Object.keys(caseData.data).includes('listing_history')">
      <listing-details :matter="caseData.data"></listing-details>
    </div>
    <!-- Crime Details & Lower Court -->
    <b-row>
      <!-- Crime Details -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="Object.keys(caseData.data).includes('crime_dic')"
      >
        <crime-details :matter="caseData.data"></crime-details>
      </b-col>
      <!-- Lower Court -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="Object.keys(caseData.data).includes('lower_court')"
      >
        <lower-court :matter="caseData.data"></lower-court>
      </b-col>
    </b-row>
    <!-- IA Details -->
    <div v-if="Object.keys(caseData.data).includes('ia')">
      <DynamicListTable
        :data="caseData.data.ia"
        title="IA Details"
      ></DynamicListTable>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// Section Imports
import BasicDetails from './BasicDetails.vue'
import CaseStatus from '../../../../dashboard/matters/matter-preview/components/status/CaseStatus.vue'
import ListingDetails from '../../../../dashboard/matters/matter-preview/components/listing-history/ListingDetails.vue'
import PetitionerRespondents from '../../../../dashboard/matters/matter-preview/components/petitioner-respondent/PetitionerRespondents.vue'
import DynamicListTable from '../../../../dashboard/matters/matter-preview/components/DynamicListTable.vue'
import CategoryDetails from '../../../../dashboard/matters/matter-preview/components/CategoryDetails.vue'
import LinkedMatters from '../../../../dashboard/matters/matter-preview/components/LinkedMatters.vue'
import LowerCourt from '../../../../dashboard/matters/matter-preview/components/LowerCourt.vue'
import CrimeDetails from '../../../../dashboard/matters/matter-preview/components/CrimeDetails.vue'
import BasicTable from '../../../../dashboard/matters/matter-preview/components/tables/BasicTable.vue'

export default {
  components: {
    BRow,
    BCol,
    // Basic Details
    BasicDetails,
    CaseStatus,
    ListingDetails,
    PetitionerRespondents,
    DynamicListTable,
    CategoryDetails,
    LinkedMatters,
    LowerCourt,
    CrimeDetails,
    BasicTable
  },
  props: {
    data: {
      default: null
    }
  },
  data() {
    return {
      caseData: this.data
    }
  }
}
</script>
