<template>
  <section class="matter-preview-wrapper">
    <b-row
      class="matter-preview"
      v-if="
        $store.state.firTrackerStore.currentFIRCase &&
        !$store.state.firTrackerStore.isFIRCaseLoading
      "
    >
      <!-- Col: Left (Matter Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card>
          <FIRCaseDetails
            :data="$store.state.firTrackerStore.currentFIRCase"
          ></FIRCaseDetails>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="matter-actions">
        <!-- <b-card> -->
        <b-button
          v-if="
            !Object.keys($store.state.firTrackerStore.currentFIRCase).includes(
              'case_exists'
            )
          "
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          class="mb-75"
          block
          @click="addToMatter()"
        >
          Add to Matter List
        </b-button>
        <b-button
          v-else-if="!$store.state.firTrackerStore.currentFIRCase.case_exists"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          class="mb-75"
          block
          @click="addToMatter()"
        >
          Add to Matter List
        </b-button>

        <b-button
          v-else
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="light"
          class="mb-75"
          block
        >
          Already Added to Matter
        </b-button>
        <!-- </b-card> -->
      </b-col>
    </b-row>

    <b-card
      v-if="
        !$store.state.firTrackerStore.currentFIRCase &&
        $store.state.firTrackerStore.isFIRCaseLoading
      "
      class="text-center mt-2"
    >
      <div class="d-flex justify-content-center my-2">
        <b-spinner label="Loading..." />
      </div>
    </b-card>

    <b-card
      v-if="
        !$store.state.firTrackerStore.currentFIRCase &&
        !$store.state.firTrackerStore.isFIRCaseLoading
      "
      class="text-center mt-2"
    >
      Please Try Again!
    </b-card>
  </section>
</template>

<script>
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
import router from '@/router'
import {
  BRow,
  BCol,
  BButtonGroup,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BSpinner,
  BLink,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
// Types
import FIRCaseDetails from './components/FIRCaseDetails.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BSpinner,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BButtonGroup,
    BOverlay,

    Logo,
    // Types
    FIRCaseDetails
  },
  methods: {
    addToMatter() {
      this.$swal({
        title: 'Confirmation',
        icon: 'info',
        text: 'You really want to add this case to your matter list?',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, Add',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          const id = router.currentRoute.params.id
          const unique_id = router.currentRoute.params.unique_id
          this.$store
            .dispatch('firTrackerStore/addToMatter', {
              ctx: this,
              payload: { fir_id: id, case_id: unique_id }
            })
            .then((result) => {
              this.$store.dispatch('firTrackerStore/fetchFIRCase', {
                id: id,
                unique_id: unique_id
              })
            })
        }
      })
    }
  },
  watch: {
    '$route.params.id'(id, unique_id) {
      console.log('Matter Id --> ', id)
      this.$store.dispatch('firTrackerStore/fetchFIRCase', {
        id: id,
        unique_id: unique_id
      })
    }
  },
  beforeCreate() {
    const id = router.currentRoute.params.id
    const unique_id = router.currentRoute.params.unique_id
    console.log('Matter Id --> ', id)
    this.$store.dispatch('firTrackerStore/fetchFIRCase', {
      id: id,
      unique_id: unique_id
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Matter Specific Styles
  .matter-preview-wrapper {
    .row.matter-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .matter-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 80% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .matter-actions {
      display: none;
    }
  }
}
</style>
